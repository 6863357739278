import { Styled } from "./styles";
import { appConfig } from "src/appConfig";
import pixiDevice from "src/pages/Welcome/images/pixi-device.png";
import pixiLogo from "src/pages/Welcome/images/light-pixi-icon.svg";
import playIcon from "../img/play-tutorial.svg";
import { FormattedMessage } from "react-intl";
import { useCallback, useEffect, MouseEvent } from "react";

export const PixiTrialistEmptyScreenSection = (): JSX.Element => {
  const storeUrl = appConfig.pixiStoreUrl;

  const handleOnCardClick = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  const handleVideoClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    const wistiaEmbed = document.querySelector("#screen-video-tutorial");

    if (wistiaEmbed) {
      // Force reset the Wistia player state
      if ((window as any)._wq && (window as any)._wq.push) {
        (window as any)._wq.push({
          id: "_all",
          onReady(video: any) {
            // Reset the player first
            video.pause();
            video.time(0);

            // Remove any existing popover
            const existingPopover = document.querySelector(".wistia_popover");
            if (existingPopover) {
              existingPopover.remove();
            }

            // Show embed and play
            (wistiaEmbed as HTMLElement).style.display = "block";
            video.play();
          },
        });
      }
    }
  }, []);

  // Add cleanup function for when video is closed
  useEffect(() => {
    const handleWistiaClose = () => {
      const wistiaEmbed = document.querySelector("#screen-video-tutorial");
      if (wistiaEmbed) {
        (wistiaEmbed as HTMLElement).style.display = "none";
      }
    };
    // Listen for Wistia popover close event
    if ((window as any)._wq) {
      (window as any)._wq.push({
        id: "_all",
        onPopoverHide: handleWistiaClose,
      });
    }

    return () => {
      // Cleanup if needed
      const wistiaEmbed = document.querySelector(".wistia_embed");
      if (wistiaEmbed) {
        (wistiaEmbed as HTMLElement).style.display = "none";
      }
    };
  }, []);

  return (
    <Styled className="pixi-trialist-empty-screen-card">
      <div
        className="pixi-trialist-empty-screen-card pixi-card"
        onClick={() => handleOnCardClick(storeUrl)}
      >
        <div className="card-image-container">
          <img className="pixi-device-img" src={pixiDevice} />
        </div>
        <div className="card-heading">
          <h4 className="title">
            <FormattedMessage
              id="ui_component.screen_list_empty.pixi_trialist.pixi_card_title"
              defaultMessage="Try our {device} Hardware"
              values={{
                device: (
                  <img
                    src={pixiLogo}
                    alt="Pixi"
                    className="inline-icon pixi-icon"
                  />
                ),
              }}
            />
          </h4>
          <p className="card-description">
            <FormattedMessage
              id="ui_component.screen_list_empty.pixi_trialist.pixi_card_description"
              defaultMessage="The lightweight digital signage solution that won't weigh down your budget."
            />
          </p>
        </div>
      </div>
      <div className="pixi-trialist-empty-screen-card watch-video-tutorial-card">
        <div className="card-image-container">
          <span
            id="screen-video-tutorial"
            className="wistia_embed wistia_async_p40hmtg6wx popover=true popoverAnimateThumbnail=true"
          />
        </div>
        <div className="card-heading" onClick={handleVideoClick}>
          <h4 className="title">
            <FormattedMessage
              id="ui_component.screen_list_empty.pixi_trialist.watch_tutorial_title"
              defaultMessage="{icon} Watch video tutorial"
              values={{
                icon: (
                  <img
                    src={playIcon}
                    alt="play"
                    className="inline-icon play-icon"
                  />
                ),
              }}
            />
          </h4>
          <p>
            <FormattedMessage
              id="ui_component.screen_list_empty.pixi_trialist.watch_tutorial_description"
              defaultMessage="Learn more about your screens capabilities with the right hardware"
            />
          </p>
        </div>
      </div>
    </Styled>
  );
};
